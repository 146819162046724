import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import DarkLogo from '../../assets/images/logo/logo-compact.png'
import IconLogo from '../../assets/images/logo/icon.png'
import {LogoWrapper} from './logo.style';

const Logo = props => {
  console.log(props.isSticky)
    return (
        <LogoWrapper {...props}>
            <Link to="/">
                {props.isSticky ? 
                  <img src={IconLogo} className="img-fluid icon-logo" alt="logo"/>
                  :  
                  <img src={DarkLogo} className="img-fluid dark-logo" alt="logo"/>
                }
            </Link>
        </LogoWrapper>
    )
}
 
Logo.propTypes = {
    justifycontent: PropTypes.string
} 

Logo.defaultProps = {
    justifycontent: 'flex-start'
}

export default Logo
